html,
body {
    line-height: 1.5;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: var(--main-bg-color);
    color: var(--main-fg-color);
    transition: background .3s, color .3s;
}

a {
    font-weight: bold;
    cursor: pointer;
    color: var(--main-fg-color);
    text-decoration: none;
    transition: color .3s;

    &:hover {
        text-decoration: underline;
    }
}

.theme-dark {
  --main-bg-color: #151615;
  --main-fg-color: #dbdfdf;
}

.theme-light {
  --main-bg-color: white;
  --main-fg-color: #555074;
}
