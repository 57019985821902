#introduction {
    padding-top: 80px;
    max-width: 700px;
    margin: 0 auto;

    #name {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 3rem;
        margin: 0;
    }

    #title {
        margin: 0;
        opacity: 0.5;
    }

    @media (max-width: 768px) {
        #name, #controls {
          padding: 0 20px;
        }
    }
}
