#reading-list {
    .book {
        display: flex;
        margin-bottom: 20px;
        font-weight: normal;
        &:hover {
            text-decoration: none;
        }
        &-cover {
            width: 50px;
            box-shadow: 0 1px 2px rgba(0,0,0,0.25);
            img {
                width: 100%;
                display: block;
            }

            .fallback-cover {
                width: 100%;
                height: 70px;
                position: relative;
                &::after {
                    content: '';
                    background: linear-gradient(270deg,hsla(0,0%,100%,0),#fff 42.19%,#000 48.96%,hsla(0,0%,100%,0));
                    top: 1px;
                    left: 2%;
                    height: calc(100% - 2px);
                    width: 3.5%;
                    position: absolute;
                }

                .title {
                    font-size: calc(50px * .12);
                    position: absolute;
                    bottom: 10%;
                    left: 10%;
                }

                .author {
                    font-size: calc(50px * .1);
                    position: absolute;
                    top: 12%;
                    left: 10%;
                    opacity: 0.5;
                }
            }
        }

        &-info {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
                margin: 0;
                font-size: 0.9em;
            }
            .author {
                margin: 0;
                opacity: 0.5;
                font-size: 0.8em;
            }
        }
    }
}