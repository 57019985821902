#details {
    margin-top: 50px;

    .detail {
        margin: 0 auto;
        max-width: 700px;
        display: flex;
        margin-bottom: 70px;

        p:first-of-type {
            margin-top: 0;
        }

        &-title {
            font-weight: bold;
            width: 120px;
        }

        &-body {
            flex: 1;
        }
    }

    .work {
        border-collapse: collapse;
        .work-year {
          opacity: .5;
          padding-right: 20px;
        }

        tr {
          height: 2em;
        }
    }

    .project {
        margin: 0;
        margin-bottom: 0.5em;
    }

    @media (max-width: 768px) {
        .detail {
            flex-direction: column;
            padding: 0 20px;

            &-title {
                margin-bottom: 1.5em;
                font-size: 1.25em;
            }
        }

        .work {
            &-year {
                display: block;
            }
        }
    }
}

.colon {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
