.toggle {
  .option {

    input {
      display: none;
    }

    input + label {
      opacity: .5;
      cursor: pointer;
      transition: opacity .3s;
    }

    input + label:hover {
      opacity: .8;
    }

    input:checked + label {
      opacity: 1;
    }
  }

  .separator {
    margin: 0 12px;
    width: 2px;
    height: 1em;
    background: var(--main-fg-color);
    opacity: .5;
    display: inline-block;
    vertical-align: text-bottom;
    transform: rotate(20deg);
  }
}
